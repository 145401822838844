$(document).ready(function(){
	
	function ModelFinder(el) {
		if (!el) return;
		var self = this;
		self.el = el;
		self.$el = $(el);
		self.$dialog = self.$el.find('.support-search__overlay-dialog');
		self.$selection = self.$el.find('.support-search__overlay-selection');
		self.$categoryField = self.$el.find('.support-search__overlay-field[data-field=productCategory]');
		self.$productField = self.$el.find('.support-search__overlay-field[data-field=product]');
		self.$result = self.$el.find('.support-search__overlay-result');
		self.$close = self.$el.find('.support-search__overlay-close');
	
		function renderProductList(data) {
			var templateHTML = '';
			Object.keys(data).forEach(function(key) {
				templateHTML += '<li class="active-result" role="option" data-value="'+key+'" tabindex="0">'+data[key]+'</li>';
			})
			self.$el.find('.support-search__overlay-field[data-field=product] .support-search__overlay-list').html(templateHTML);
			self.$productField.removeClass('disabled');
		}
	
		function renderResult(html) {
			self.$result.html(html);
			self.$dialog.addClass('hasResult');
			self.$result.show();
		}
	
		function handleCategorySelection(value, url) {
			if (self.productCategory !== value) {
				self.$result.empty();
				self.$result.hide();
				self.$dialog.removeClass('hasResult');
				self.$productField.addClass('disabled');
				self.$productField.find('.selected-result').text('Product');
				self.productCategory = value;
				$.ajax({
					type: 'POST',
					url: url,
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					data: 'productCategory=' + value,
					success: function(data) {
						renderProductList(data);
					},
					error: function() {
				
					}
				});
			}
		}
	
		function handleProductSelection(value, url) {
			if (self.product !== value) {
				self.product = value;
				$.ajax({
					type: 'POST',
					url: url,
					contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
					data: 'productCategory=' + self.productCategory + '&findCategory=' + value,
					success: function(data) {
						renderResult(data);
					},
					error: function() {
				
					}
				});
			}
		}
	
		function bindEventListener() {
			self.$selection.on('click', function(e) {
				$(this).toggleClass('selected');
				self.$el.find('.support-search__overlay-list').hide();
				$(this).siblings('.support-search__overlay-list').toggle($(this).hasClass('selected'));
			});
		
			self.$el.on('click', '.support-search__overlay-list li', function() {
				var $field = $(this).parents('.support-search__overlay-field');
				var fieldType = $field.data('field');
			
				$field.find('.selected-result').text($(this).text());
				self.$selection.removeClass('selected');
				$(this).parent().hide();
			
				if (fieldType == 'productCategory') {
					handleCategorySelection($(this).data('value'), $field.data('action'));
				} else {
					handleProductSelection($(this).data('value'), $field.data('action'));
				}
			});
		
			self.$close.on('click', function() {
				self.$el.hide();
				$('body').css('overflow', '');
			});

			self.$el.on('click', function(e) {
				if ($(e.target).hasClass('support-search__overlay')) {
				  	self.$el.hide();
				  	$('body').css('overflow', '');
				}
			});
		}
	
		function init() {
			bindEventListener();
		}
	
		init();
	}

	function SupportSearch(el) {
		var self = this;
		self.el = el;
		self.$el = $(el);

		self.$input = self.$el.find('#search-keyword');
		self.$placeholder = self.$el.find('.support-search__placeholder');
		self.$overlay = self.$el.find('.support-search__overlay');
		self.$modelfinder = self.$el.find('.support-search__assist');
		self.$searchAction = self.$el.find('.support-search__action');
		self.$predictiveUrl = self.$input.data('predictive-url');
		self.inputValue = '';

		self.searchHistory = new Map();

		self.doSearch = function (inputValue) {
			var searchTxt = { search: xssfilter(inputValue, 'form') };
			ajax.call(self.$predictiveUrl, searchTxt, 'json', function (data) {
				self.searchHistory.set(inputValue, data.data ? data.data[0] : data);
				if (!!searchTxt.search && searchTxt.search.toLowerCase() === 'bcp2a') {
					var item = {
						linkText: '0',
						resultText: '0',
						predictive: [
							{
								actionCause: "documentOpen",
								analyticsClick: "https://5fh0343mba.execute-api.us-east-1.amazonaws.com/v1/ua/v15/analytics/click",
								analyticsSearch: "https://5fh0343mba.execute-api.us-east-1.amazonaws.com/v1/ua/v15/analytics/search",
								anonymous: true,
								category: "",
								cs_category_id: "",
								cs_sub_category_id: "",
								documentPosition: 1,
								documentTitle: "BCP2A.AUSALA",
								documentUri: "https://www.lg.com/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf",
								documentUriHash: "",
								documentUrl: "https://www.lg.com/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf",
								language: "en",
								model: "BCP2A",
								name: "LG Ultrasonic Cleanser",
								originLevel1: "LGRepair",
								queryText: "BCP2A",
								rankingModifier: "",
								searchQueryUid: "",
								sourceName: "Repair",
								token: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ2OCI6dHJ1ZSwib3JnYW5pemF0aW9uIjoibGdlbGVjdHJvbmljc3VzYW5vbnByb2R1Y3Rpb24xajVnZ2pqamgiLCJ1c2VySWRzIjpbeyJwcm92aWRlciI6IkVtYWlsIFNlY3VyaXR5IFByb3ZpZGVyIiwibmFtZSI6Imluc2VydHlvdXJlbWFpbGhlcmVAeW91cmRvbWFpbi5jb20iLCJ0eXBlIjoiVXNlciJ9XSwicm9sZXMiOlsicXVlcnlFeGVjdXRvciJdLCJleHAiOjE2Njg2OTQwOTAsImlhdCI6MTY2ODYwNzY5MH0.M8RAgEioqU8-W7wOAyqWHJr8XNMSul4-6qUGqCEtPEg",
								totalCount: "0",
								url: "/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf"
							}
						]
					};
					self.ajaxSuccess(item);
				} else if (data.data) {
					self.ajaxSuccess(data.data[0]);
				} else {
					self.ajaxSuccess(data);
				}
			});
		};

		self.$result = self.$el.find('.support-search__result');
		self.$noResult = self.$el.find('.support-search__noResult');
		self.$resultList = self.$el.find('.support-search__list');
		self.ajaxSuccess = function (data) {

			if (!data || data.Flag == 'N') {
				self.$noResult.show();
				self.$resultList.hide();
			} else {
				var coveoToken;
				var coveoSearchUrl;
				var coveoClickUrl;
				var isCoveoSearch = window.location.href.includes('/us/support') || window.location.href.includes('/mylg/product-registration') || window.location.href.includes('/us/search');
		
				var searchTxt = xssfilter(self.inputValue, 'form');
				if (data && data.predictive[0] && data.predictive[0].token && isCoveoSearch) {
					coveoToken = data.predictive[0].token;
					coveoSearchUrl = data.predictive[0].analyticsSearch;
					coveoClickUrl = data.predictive[0].analyticsClick;
					coveoSearchEvent(data.predictive[0], searchTxt, coveoToken, coveoSearchUrl);
				}
		
				var len = data.predictive ? data.predictive.length : 0;
				var html = '';
		
				if (len > 0) {
					len = Math.min(len, 4);
			
					for (var i = 0; i < len; i++) {
						var tempCateHTML = '';
						if (data.predictive[i].cate) tempCateHTML = tempCateHTML + ' data-category=' + data.predictive[i].cate;
						else if (data.predictive[i].cs_category_id) tempCateHTML = tempCateHTML + ' data-category=' + data.predictive[i].cs_category_id;
						if (data.predictive[i].subcate) tempCateHTML = tempCateHTML + ' data-subcategory=' + data.predictive[i].subcate;
						else if (data.predictive[i].cs_sub_category_id) tempCateHTML = tempCateHTML + ' data-subcategory=' + data.predictive[i].cs_sub_category_id;
				
						if (coveoToken && isCoveoSearch) {
							tempCateHTML = tempCateHTML + ' data-action-cause=' + data.predictive[i].actionCause;
							tempCateHTML = tempCateHTML + ' data-document-position=' + data.predictive[i].documentPosition;
							tempCateHTML = tempCateHTML + ' data-document-title="' + escapeQuote(data.predictive[i].documentTitle) + '"';
							tempCateHTML = tempCateHTML + ' data-document-uri=' + data.predictive[i].documentUri;
							tempCateHTML = tempCateHTML + ' data-document-uri-hash=' + data.predictive[i].documentUriHash;
							tempCateHTML = tempCateHTML + ' data-document-url=' + data.predictive[i].documentUrl;
							tempCateHTML = tempCateHTML + ' data-language=' + (data.predictive[i].language || 'en');
							tempCateHTML = tempCateHTML + ' data-origin-level="' + escapeQuote(data.predictive[i].originLevel1) + '"';
							tempCateHTML = tempCateHTML + ' data-search-query-uid=' + data.predictive[i].searchQueryUid;
							tempCateHTML = tempCateHTML + ' data-source-name="' + escapeQuote(data.predictive[i].sourceName) + '"';
							tempCateHTML = tempCateHTML + ' data-modalid=' + data.predictive[i].modalId;
							if (data.predictive[i].rankingModifier) {
								tempCateHTML = tempCateHTML + ' data-ranking-modifier="' + escapeQuote(data.predictive[i].rankingModifier) + '"';
							}
						}
				
						html += ('<li><a rel="nofollow" href="' + data.predictive[i].url + '"' + tempCateHTML + '>' +
							'<span class="model-name">' + data.predictive[i].model + '</span>' +
							'<span class="product-name">' + data.predictive[i].name + '</span>' +
							'<span class="category-name">' + data.predictive[i].category + '</span>' +
							'</a></li>');
					}
					self.$resultList.html(html);
					self.$noResult.hide();
			        self.$resultList.show();
				} else {
					self.$noResult.show();
			        self.$resultList.hide();
				}
			}
		};

		function bindEventListener() {
			self.$input.on('focus', function () {
				self.inputValue = self.$input.val();
				self.$placeholder.hide();
				if (self.inputValue.length == 0) self.$result.hide();
			});
	
			self.$input.on('blur', function () {
				self.inputValue = self.$input.val();
				if (self.inputValue.length == 0) {
					self.$placeholder.show();  
					self.$result.hide();
				};
			});
	
			self.$input.on('input', debounce(function () {
				self.inputValue = self.$input.val();
				if (self.inputValue.length == 0) self.$result.hide();
				if (self.inputValue.length > 1) {
					self.$result.show();
					if (self.searchHistory.has(self.inputValue)) {
						self.ajaxSuccess(self.searchHistory.get(self.inputValue));
					} else {
						self.doSearch(self.inputValue);
					}
				}
			}));
			
			self.$modelfinder.on('click', function(e) {
				e.preventDefault();
				self.$overlay.show();
				$('body').css('overflow', 'hidden');
			});
			
		    self.$searchAction.on('click', function() {
		    	var searchTxt = xssfilter($('#search-keyword').val(), 'form');
				$('#search-keyword').val(searchTxt);
				if (!$('.search-common').is('.auto-validation-form')) {
					if ($.trim(searchTxt) != '') {
						if ($('.search-common').data('cansubmit') == 1) {
							$('.search-common').submit();
						} else {
							return false;
						}
					} else {
						return false;
					}
				} else {
					$('.search-common').submit();
				}
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					"event": "bs_lg_event",
					"event_name": "support_search",
					"event_params": {
						"search_term": searchTxt,
						"product_name": undefined,
						"product_id": undefined,
						"search_category": ""
					}
				});
			});

			self.$resultList.on('click', 'li a', function () {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					"event": "bs_lg_event",
					"event_name": "support_search",
					"event_params": {
						"search_term": self.inputValue,
						"product_name": $(this).find('.product-name').text(),
						"product_id": $(this).find('.model-name').text(),
						"search_category": $(this).find('.category-name').text()
					}
				});
			});
		}

		function init() {
			bindEventListener();
			self.$result.hide();
			new ModelFinder(self.$overlay[0]);
		}
		
		init();
	}
	
	$('.support-search').each(function (key,el) {
	  new SupportSearch(el);
	});
	
	function BrowseByTopic(el) {
		var self = this;
		self.el = el;
		self.$el = $(el);
		self.$cardContianer = self.$el.find('.flex-row');

		self.renderTopics  = function(data) {
			var html = '';
			data.forEach(function(topic, index) {
				var marginClass = '';
				if ((index+1) % 3 === 0) {
					marginClass += ' no-margin';
				}
				if ((index+1) % 2 === 0) {
					marginClass += ' no-margin-xs';
				}
				html += (
					'<div class="card' + marginClass + '">' +
					'<div class="inner">' +
					'<div class="card-top">' +
					'<div class="card-left">' +
					'<div class="eyebow">' + topic.category + '</div>' +
					'<div class="name">' + topic.name + '</div>' +
					'</div>' +
					'</div>' +
					'<a class="card-bottom" href="' + topic.url + '">' +
					'<svg class="normal" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">' +
					'<path fill-rule="evenodd" clip-rule="evenodd" d="M40.5 21C40.5 31.7696 31.7696 40.5 21 40.5C10.2304 40.5 1.5 31.7696 1.5 21C1.5 10.2304 10.2304 1.5 21 1.5C31.7696 1.5 40.5 10.2304 40.5 21ZM42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21ZM20.3322 10.0007L30.3012 20.2813H9V21.7187H30.3012L20.3322 31.9993L21.3641 33L33.0005 21L21.3641 9L20.3322 10.0007Z" fill="black"/>' +
					'</svg>' +
					'<svg class="hover" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">' + 
					'<circle cx="21" cy="21" r="21" fill="#EA1917"/>' + 
					'<path fill-rule="evenodd" clip-rule="evenodd" d="M29.3012 20.2813L19.3322 10.0007L20.3641 9L32.0005 21L20.3641 33L19.3322 31.9993L29.3012 21.7187H8V20.2813H29.3012Z" fill="white"/>' + 
					'</svg>' + 
					'</a>' +
					'</div>' +
					'</div>'
				);
			})
			self.$cardContianer.prepend(html);
		};

		function init() {
			$.getJSON('/us/support/data-ajax/browse-by-topic.json', function(browseByTopicJson) {
				self.renderTopics(browseByTopicJson.data);
			});
		}

		init();
	}
	
	$('.browse-by-topic').each(function(key,el) {
	  new BrowseByTopic(el);
	});

	// tagging
	function SupportTagging() {
		var self = this;
		window.dataLayer = window.dataLayer || [];

		function fetchTag(section_title, section_cta, article_id, article_title, article_category, article_tags, content_type) {
			var tagObj = {
				"event": "bs_lg_event",
				"event_name": "support",
				"event_params": {
					"support_hub_section": section_title || undefined,
					"support_hub_cta": section_cta || undefined,
					"content_type": content_type || undefined,
					"article_id": article_id || undefined,
					"article_name": article_title || undefined,
					"article_category": article_category || undefined,
					"article_tags": article_tags || undefined
				}
			};
			window.dataLayer.push(tagObj);
//			console.log('datalayer updated', JSON.stringify(tagObj)); debugger
		}

		function initHowCanWeHelpTagging() {
			var $howCanWeHelp = $('.guided-experience');
			var section_title = $howCanWeHelp.find('.guided-experience-headline').text();

			$howCanWeHelp.find('.flex-card').on('click', function () {
				fetchTag(
					section_title,
					$(this).find('span').text(),
					undefined,
					undefined,
					undefined,
					undefined
				);
			});
		}

		function initBrowseByTopicTagging() {
			var $el = $('.browse-by-topic');
			var section_title = $el.find('.title').text();
			var section_cta = $el.find('.see-all').text();

			$el.on('click', '.card a, .card-grey a', function () {
				var $card = $(this).parents('.card, .card-grey');
				fetchTag(
					section_title,
					$card.find('.name').text(),
					$card.data('id'),
					undefined,
					undefined,
					$card.find('.eyebow').text(),
					'support_article'
				);
			});

			$el.find('.see-all').on('click', function () {
				fetchTag(
					section_title,
					section_cta
				);
			});
		}

		function initJoinUs() {
			var $el = $('.hero-banner');
			var section_title = $el.find('.hero-banner__header').text().trim();
			var section_cta = $el.find('.hero-banner__button').text();

			$el.find('.hero-banner__button').on('click', function () {
				fetchTag(
					section_title,
					section_cta
				);
			});
		}

		function initRegisterProduct() {
			var $el = $('.benefit-images');
			var section_title = $el.find('.benefit-images__title').text().trim();
			var section_cta = $el.find('.benefit-images__register').text();

			$el.find('.benefit-images__register').on('click', function () {
				fetchTag(
					section_title,
					section_cta
				);
			});
		}

		function initMoreSupportOptions() {
			var $el = $('.more-support-options');
			var section_title = $el.find('.more-support-options__headline').text().trim();

			$el.find('.card').on('click', function (e) {
				e.preventDefault();
				var $card = $(this);
				fetchTag(
					section_title,
					$card.find('.card-title').text(),
					$card.data('id'),
					undefined,
					undefined,
					undefined
				);
				var link = $card.find('.card-link').attr('href');
				window.location.href = link;
			});
		}
		// init
		initHowCanWeHelpTagging();
		initBrowseByTopicTagging();
		initMoreSupportOptions();
		initRegisterProduct();
		initJoinUs();
	}
	new SupportTagging();
});
